import React from 'react';
import ApplyHomeComponent from '../component/Apply/applyHomeComponent';
import FooterComponent from '../component/footerComponent';
import Layout from '../component/layout';
import { graphql } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
const Contact = props => {
  const {
    data: {
      wpPage: { seo },
    },
    pageContext: { title },
  } = props;
  return (
    <Layout title={title} seo={seo}>
      <GoogleReCaptchaProvider reCaptchaKey='6Lcvg20kAAAAAEks5ix4yEOanhiJosUOskApRNgj'>
        <ApplyHomeComponent
          contactHomeTitle='Reach out to our team'
          contactHomeDescription='Grow your business with our expertise and Agile methodologies. We will build technology solutions that allow you to focus on your core business instead of mitigating risks, overseeing the quality, or coordinating teams. '
          firstName='First name'
          lastName='Last name'
          email='Email'
          message='Message'
          formButton='Contact us'
          checboxText='You agree to our friendly '
          privacyText='privacy policy.'
        />
        <FooterComponent />
      </GoogleReCaptchaProvider>
    </Layout>
  );
};
export const query = graphql`
  query {
    wpPage(slug: { eq: "contact-motomtech" }) {
      seo {
        metaDesc
        title
      }
    }
  }
`;
export default Contact;
