import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useForm, ValidationError } from '@formspree/react';
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import PhoneIcon from '../../../static/assets/phone-icon.svg';
import { navigate } from 'gatsby';

// This file is used at : [ contact.js ]

const ApplyHomeComponent = (props) => {
  const { firstName, lastName, email, formButton } = props;
  // const { executeRecaptcha } = useGoogleReCaptcha();
  const [state, handleSubmit] = useForm('mzbqevwl');

  useEffect(() => {
    if (state.succeeded) {
      toast.success('Message sent successfully', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate('/thank-you');
    }
  }, [state]);

  return (
    <div className='contact-home-section'>
      <div className='full-container'>
        <div className='contact-home-section-wrapper'>
          <div className='row'>
            <div className='col-md-6 contact-home-section-col'>
              <div className='contact-home-section-col-text'>
                <p className='contact-home-section-col-text-title'>
                  Reach out to our team
                </p>
                <p className='contact-home-section-col-text-description'>
                  Grow your business with our expertise and Agile methodologies.
                  We will build technology solutions that allow you to focus on
                  your core business instead of mitigating risks, overseeing the
                  quality, or coordinating teams.
                </p>
                <a
                  className='contact-home-section-col-text-phoneNr'
                  href={`tel:${'(385) 267-8188'}`}
                >
                  <PhoneIcon />
                  <p>(385) 267-8188</p>
                </a>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='contact-home-section-form'>
                <form onSubmit={handleSubmit}>
                  <div className='row'>
                    <div className='col-6'>
                      <label className='contact-home-section-form-label'>
                        {firstName}
                      </label>
                      <div className='contact-home-section-form-input-section'>
                        <input
                          type='text'
                          className={`contact-home-section-form-input ${''}`}
                          placeholder='First name'
                          id='firstName'
                          name='firstname'
                          // {...register("firstname", {
                          //   required: "Enter your first name!",
                          // })}
                        />
                        <div className='invalid-feedback'>
                          {/* {errors.firstName?.message} */}
                          <ValidationError
                            prefix='Firstname'
                            field='text'
                            errors={state.errors}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-6'>
                      <label className='contact-home-section-form-label'>
                        {lastName}
                      </label>
                      <div className='contact-home-section-form-input-section'>
                        <input
                          type='text'
                          className={`contact-home-section-form-input ${''}`}
                          placeholder='Last name'
                          id='lastName'
                          name='lastname'
                          // {...register("lastname", {
                          //   required: "Enter your last name!",
                          // })}
                        />
                        <div className='invalid-feedback'>
                          <ValidationError
                            prefix='Lastname'
                            field='text'
                            errors={state.errors}
                          />{' '}
                          {/* {errors.lastName?.message} */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <label className='contact-home-section-form-label'>
                    {email}
                  </label>
                  <div className='contact-home-section-form-input-section'>
                    <input
                      type='email'
                      className={`contact-home-section-form-input ${''}`}
                      placeholder='you@company.com'
                      id='email'
                      name='email'
                      // {...register("email", {
                      //   required: "Enter your email!",
                      // })}
                    />
                    <div className='invalid-feedback'>
                      <ValidationError
                        prefix='Email'
                        field='email'
                        errors={state.errors}
                      />{' '}
                      {/* {errors.email?.message} */}
                    </div>
                  </div>
                  <label className='contact-home-section-form-label'>
                    Message
                  </label>
                  <div className='contact-home-section-form-input-section'>
                    <textarea
                      type='text'
                      className={`contact-home-section-form-input ${''}`}
                      placeholder='Message'
                      id='message'
                      name='message'
                      rows={'10'}
                      // {...register("email", {
                      //   required: "Enter your email!",
                      // })}
                    />
                    <div className='invalid-feedback'>
                      <ValidationError
                        prefix='Email'
                        field='email'
                        errors={state.errors}
                      />{' '}
                      {/* {errors.email?.message} */}
                    </div>
                  </div>
                  <div className='d-flex justify-content-end'>
                    <button
                      type='submit'
                      className='contact-home-section-form-button'
                    >
                      {!state.submitting ? (
                        <>{formButton}</>
                      ) : (
                        <div
                          className='spinner-border text-light'
                          role='status'
                        >
                          <span className='visually-hidden'>Loading...</span>
                        </div>
                      )}
                    </button>
                  </div>
                </form>
              </div>
              <ToastContainer
                position='top-right'
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <ToastContainer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ApplyHomeComponent;
// const {
//   register,
//   handleSubmit,
//   formState: { errors },
//   reset,
// } = useForm({
//   mode: "onBlur",
//   reValidateMode: "onChange",
//   defaultValues: {},
// });
